import type { WP_REST_API_Media, WP_REST_API_Post } from "wp-types";
import { Config } from "./config";
import { IArticle } from "../models/IArticle";
import { IImage } from "../models/IImage";

export const WordpressHelper = {
    decodeHTMLEntities(text: string): string {
        const textArea = document.createElement("textarea");
        textArea.innerHTML = text;
        return textArea.value;
    },

    getPosts(featured = true): Promise<IArticle[]> {
        const filter = featured ? `?tags=${Config.featuredTagID}` : "";
        const url = `${Config.siteBaseUrl + Config.wpJSONApiUrl}posts${filter}`;
        return fetch(url)
            .then((response) => response.json())
            .then(async (data: WP_REST_API_Post[]) => {
                const articles: IArticle[] = [];
                // eslint-disable-next-line no-restricted-syntax
                for await (const datum of data) {
                    const media = datum.featured_media;
                    let image = null;
                    if (media) {
                        try {
                            image = await WordpressHelper.getMedia(media);
                        } catch (error: unknown) {
                            console.error(error);
                        }
                    }
                    const formattedTitle = datum.title.rendered.replace(/<[^>]*>?/gm, "");
                    const formattedDescription = datum.excerpt.rendered.replace(/<[^>]*>?/gm, "");
                    const a: IArticle = {
                        title: formattedTitle,
                        shortDescription: formattedDescription,
                        link: datum.link,
                        previewImage: image || {
                            width: 1429,
                            height: 750,
                            url: "https://sitechecker.pro/wp-content/uploads/2017/12/404.png",
                        },
                    };
                    articles.push(a);
                }
                return articles;
            });
    },

    getMedia(id: number): Promise<IImage> {
        const url = `${Config.siteBaseUrl + Config.wpJSONApiUrl}media/${id}`;
        return fetch(url)
            .then((response) => response.json())
            .then((data: WP_REST_API_Media) => {
                if (typeof data.media_details.width === "number" && typeof data.media_details.height === "number") {
                    const img: IImage = {
                        width: data.media_details.width,
                        height: data.media_details.height,
                        url: data.guid.rendered,
                    };

                    return img;
                }
                throw new TypeError("width or height either not defined or wrong type");
            });
    },
};
