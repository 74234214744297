import React, { ReactElement, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { Article } from "./Article";
import { WordpressHelper } from "./helper/WordpressHelper";
import { IArticle } from "./models/IArticle";

export function Slider(): JSX.Element {
    const [wordpressData, setWordpressData] = React.useState<IArticle[] | null>(null);

    useEffect(() => {
        WordpressHelper.getPosts()
            .then((articles: IArticle[]) => setWordpressData(articles))
            .catch(console.error);
    }, []);

    if (wordpressData) {
        const elements: ReactElement<typeof Article>[] = [];
        wordpressData.forEach((article) => {
            elements.push(<Article article={article} key={Math.random() * 20} />);
        });
        return (
            <>
                <Carousel timeout={800} interval={7000} animation="slide" navButtonsAlwaysVisible>
                    {elements}
                </Carousel>
            </>
        );
    }
    return <div>Lade Artikel...</div>;
}
