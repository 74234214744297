import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useArticleStyles = makeStyles((theme: Theme) => ({
    readMoreButton: {
        color: "white",
        fontSize: 40,
        fontWeight: "bolder",
        border: "5px solid white",
        textTransform: "capitalize",
        position: "absolute",
        bottom: 20,
        right: 50,
        background: "rgba(0, 0, 0, 0.6)",
        [theme.breakpoints.down("xs")]: {
            fontSize: 25,
            border: "2px solid white",
            bottom: 20,
            right: 30,
        },
    },
    article: {
        width: "100%",
        overflow: "hidden",
        height: "100%",
        minHeight: "700px",
        position: "relative",
        transition: "300ms",
        cursor: "pointer",
        backgroundAttachment: "local",
        backgroundPositionY: "top",
        "&:hover": {
            filter: "brightness(115%)",
        },
        [theme.breakpoints.down("xs")]: {
            minHeight: "800px",
        },
    },
    articleDetailArea: {
        textOverflow: "ellipsis",
        position: "absolute",
        overflow: "hidden",
        left: 0,
        background: "rgba(0, 0, 0, 0.6)",
        color: "white",
        padding: "15px",
        width: "35%",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
            top: 400,
            width: "100%",
        },
    },
    articleTitle: {
        fontSize: 30,
        textAlign: "left",
        fontWeight: "bolder",
        padding: 10,
        [theme.breakpoints.down("xs")]: {
            padding: 6,
        },
    },
    articleDescription: {
        fontSize: 20,
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
            textAlign: "left",
            maxWidth: "90%",
            fontSize: 15,
        },
    },
}));
