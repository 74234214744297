import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { SiteTheme } from "./styles/Theme";
import { Slider } from "./Slider";

export function App(): JSX.Element {
    return (
        <MuiThemeProvider theme={SiteTheme}>
            <Slider />
        </MuiThemeProvider>
    );
}
