import React from "react";
import { Card, CardContent, CardMedia, Typography, Button, useMediaQuery } from "@material-ui/core";
import { IArticle } from "./models/IArticle";
import { useArticleStyles } from "./styles/ArticleStyle";
import { Config } from "./helper/config";
import { WordpressHelper } from "./helper/WordpressHelper";
import { SiteTheme } from "./styles/Theme";

export function Article(props: { article: IArticle }): JSX.Element {
    const classes = useArticleStyles();
    const [stylingMedia, setStylingMedia] = React.useState<React.CSSProperties>({});
    const [stylingContent, setStylingContent] = React.useState<React.CSSProperties>({});
    const smallScreen = useMediaQuery<typeof SiteTheme>((theme) => theme.breakpoints.down("xs"));

    const rootElement = document.getElementById("articleSliderRoot");

    const smallerStyleMedia: React.CSSProperties = {
        backgroundSize: "70%",
        backgroundPositionX: "right",
        backgroundPositionY:
            props.article.previewImage.height > (rootElement ? rootElement.offsetHeight + 300 : 1000) &&
            props.article.previewImage.height * 0.65 > (rootElement ? rootElement.offsetHeight + 200 : 1000)
                ? "-200px"
                : "top",
    };

    const smallerStyleContent: React.CSSProperties = {
        background: `url(${Config.imageEmptyBackground}) rgba(0,0,0)`,
        backgroundSize: "cover",

        width: "30%",
    };

    let style: React.CSSProperties = {};
    let contentStyle: React.CSSProperties = {};

    const handleResize = (): void => {
        // If Image at 130% is still smaller than the root Element
        const small = rootElement ? props.article.previewImage.width * 1.3 <= rootElement.offsetWidth : false;

        if (small) {
            style = smallerStyleMedia;
            contentStyle = smallerStyleContent;
        }

        setStylingMedia(style);
        setStylingContent(contentStyle);
    };

    React.useEffect(() => {
        const width = rootElement ? rootElement.offsetWidth : 600;
        const newHeight = (width / props.article.previewImage.height) * props.article.previewImage.height;
        if (smallScreen) {
            style = {
                backgroundSize: "contain",
                backgroundPositionX: "center",
                backgroundPositionY: "top",
                left: 0,
                minHeight: newHeight,
            };
            contentStyle = {
                top: newHeight - 350,
            };
        }
        setStylingMedia(style);
        setStylingContent(contentStyle);
    }, [smallScreen]);

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
    }, []);

    return (
        <Card
            raised
            onClick={() => {
                window.location.href = props.article.link;
            }}
        >
            <CardMedia className={classes.article} image={props.article.previewImage.url} style={stylingMedia}>
                <CardContent className={classes.articleDetailArea} style={stylingContent}>
                    <Typography className={classes.articleTitle}>
                        {WordpressHelper.decodeHTMLEntities(props.article.title)}
                    </Typography>

                    <Typography className={classes.articleDescription}>
                        {WordpressHelper.decodeHTMLEntities(props.article.shortDescription)}
                    </Typography>
                </CardContent>
                <Button
                    variant="outlined"
                    className={classes.readMoreButton}
                    onClick={() => {
                        window.location.href = props.article.link;
                    }}
                >
                    Weiterlesen
                </Button>
            </CardMedia>
        </Card>
    );
}
